<template>
  <div>
    <v-dialog
        v-model="dialog"
        :width="computedWidth"
        :fullscreen="isFullscreen"
        no-click-animation
        persistent
        scrollable
        @keydown.esc="handleAttemptClose"
    >
      <dynamicComponent
          v-for="type in result.content || []"
          :key="type"
          :type="type"
          :result="result"
          :dialog="dialog"
          :additional="{ extraAttr: dialogAttributes }"
          @update:attempt-close-dialog="handleAttemptClose"
      />
    </v-dialog>

    <confirmationDialog
        :value="showConfirmationDialog"
        title="Notice"
        :message="language.componentLabel.msgChangesMade"
        :key="showConfirmationDialog"
        @confirmation-ok="checkCloseDialog($event)"
        maxWidth="350"
        @confirmation-cancel="checkCloseDialog(false)"
    ></confirmationDialog>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineProps, defineEmits } from 'vue';
import dynamicComponent from '@/commonComponents/dynamicComponent.vue';
import confirmationDialog from "@/commonComponents/confirmationDialog.vue";
import { cloneDeep,isEqual } from "lodash-es";
import { useStore } from "@/store"

const props = defineProps({
  result: Object,
  dialogAttributes: Object
});
const store = useStore();
const emit = defineEmits();

const dialog = ref(true);
const showConfirmationDialog = ref(false);
const formChanged = ref(false);
const originalFormData = ref({});

const computedWidth = computed(() => {
  return props.result?.json?.dialogFormat?.minWidth || props.result?.formWidth || 600;
});

const isFullscreen = computed(() => {
  return props.result?.json?.dialogFormat?.maximized === true;
});

const handleAttemptClose = () => {
  if (formChanged.value) {
    showConfirmationDialog.value = true;
  } else {
    forceCloseDialog();
  }
};

const storeOriginalData = () => {
  originalFormData.value = cloneDeep(props.result.valueLine);
};

const detectFormChanges = () => {
  formChanged.value = !isEqual(originalFormData.value, props.result.valueLine);
};

const checkCloseDialog = (confirm) => {
  if (!confirm) {
    showConfirmationDialog.value = false;
    return;
  }
  showConfirmationDialog.value = false;
  forceCloseDialog();
};

const forceCloseDialog = () => {
  store.commit('closeDialog', props.result.dialogRef);
  emit('close-dialog');
  dialog.value = false;
};

watch(
    () => props.result,
    () => {
      detectFormChanges();
    },
    { deep: true }
);

onMounted(() => {
  storeOriginalData();
});
</script>